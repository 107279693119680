<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title><a @click="goToList">{{ site.title }}</a>&nbsp;-&nbsp;{{ entity.title }}</v-card-title>
                <v-card-text>
                    <EntityTabLinks />
                    <v-form class="py-6">
                        <v-row>
                            <v-col>
                                <div class="v-title pb-2">Настройки уведомлений</div>
                                <p class="pb-2">
                                    Чтобы получать уведомления о новых заказах надо:<br>
                                    1. В телеграм создать группу;<br>
                                    2. Добавить в нее нашего бота <a href="https://t.me/marketoria_actions_bot" target="_blank">@marketoria_actions_bot</a>;<br>
                                    3. В настройках группы найти ссылку на нее;<br>
                                    4. Отправить эту ссылку сообщением в <a href="https://t.me/username_to_id_bot" target="_blank">IDBot</a>;<br>
                                    5. Полученный в ответе id (пример -989898989) группы вставить в поле ниже.<br>
                                    <i>Для получения ответа в IDBot возможно поднадобится включить VPN.</i>
                                </p>
                                <v-text-field
                                    v-model="setting.telegram_chat_id"
                                    outlined
                                    label="ID группы в телеграм для уведомлений"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-divider />
                    <v-form class="py-6">
                        <v-row>
                            <v-col>
                                <div class="v-title">Настройки оформления заказа</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="setting.order_cutlery"
                                    class="pa-0 ma-0"
                                    label="Добавлять в заказ приборы"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-divider />
                    <v-form class="py-6">
                        <v-row>
                            <v-col>
                                <div class="v-title pb-4">Настройки онлайн оплаты</div>
                                <v-row>
                                    <v-col>
                                        <v-select
                                            v-model="setting.payment.type"
                                            label="Платежная система"
                                            :items="paymentTypes"
                                            item-text="label"
                                            item-value="value"
                                            outlined
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <template v-if="setting.payment.type === 'paygine'">
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="setting.payment.paygine_sector"
                                                outlined
                                                label="Sector ID"
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="setting.payment.paygine_signature_password"
                                                outlined
                                                label="Пароль для формирования цифровой подписи"
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select
                                                v-model="setting.payment.taxation_system"
                                                label="Система налогообложения"
                                                :items="taxationSystems"
                                                item-text="label"
                                                item-value="value"
                                                outlined
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-if="setting.payment.type === 'cloudpayments'">
                                    <p class="py-2 ma-0">
                                        Для отображения данных об оплате в системе необходимо в CloudPayments, в настройках сайта включить 2 уведомления <b>Pay</b> и <b>Fail</b>.<br>
                                        Для обоих уведомлений прописать следующие параметры:<br>
                                        <b>Адрес: https://api.marketoria.com/api/common/payments</b><br>
                                        Кодировка: UTF-8 (выставлен по умолчанию)<br>
                                        HTTP метод: POST (выставлен по умолчанию)<br>
                                        Формат запроса: CloudPayments (выставлен по умолчанию)<br>
                                    </p>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="setting.payment.cloudpayments_public_id"
                                                outlined
                                                label="Public ID"
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-divider />
                    <v-form class="py-6">
                        <v-row>
                            <v-col>
                                <div class="v-title pb-2">Настройки Яндекс доставки</div>
                                <p class="pb-2">
                                    Для успешного вызова курьеров, заполните все поля ниже. Координаты и полный адрес нужно скопировать с <a href="https://maps.yandex.ru/" target="_blank">Яндекс Карт</a>.
                                </p>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model="setting.yandex_delivery.token"
                                            outlined
                                            label="Токен для доступа к АПИ"
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6" class="d-flex align-center">
                                        <span class="pr-3">В случае отмены заказа:</span>
                                        <v-switch
                                            v-model="setting.yandex_delivery.optional_return"
                                            :label="setting.yandex_delivery.optional_return ? 'курьер оставляет товар себе' : 'требуется вернуть товар'"
                                            class="ma-0 pa-0"
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model="setting.yandex_delivery.address"
                                            outlined
                                            label="Полный адрес заведения"
                                            hide-details
                                        />
                                    </v-col>
                                    <template v-if="setting.yandex_delivery.coordinates">
                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="setting.yandex_delivery.coordinates[1]"
                                                outlined
                                                label="Координаты заведения (широта)"
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="setting.yandex_delivery.coordinates[0]"
                                                outlined
                                                label="Координаты заведения (долгота)"
                                                hide-details
                                            />
                                        </v-col>
                                    </template>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="setting.yandex_delivery.comment"
                                            outlined
                                            label="Комментарий для курьера (как найти заведение)"
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model="setting.yandex_delivery.contact_name"
                                            outlined
                                            label="Имя контактного лица"
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model="setting.yandex_delivery.contact_phone"
                                            outlined
                                            label="Телефон контактного лица"
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import EntityTabLinks from '../../components/sites/EntityTabLinks';

    import { imageSrc } from '../../helpers';

    export default {
        name: 'EntitySetting',

        components: {
            EntityTabLinks,
        },

        data() {
            return {
                imageSrc,
                setting: {
                    min_order_price: 0,
                    order_cutlery: false,
                    payment: {},
                    delivery: {},
                    yandex_delivery: {
                        coordinates: [],
                        optional_return: false
                    },
                    files: {
                        legal_information: null
                    }
                },
                formatedPrice: 0,
                paymentTypes: [
                    { value: null, label: 'Не подключена' },
                    { value: 'paygine', label: 'Paygine' },
                    { value: 'cloudpayments', label: 'CloudPayments' }
                ],
                taxationSystems: [
                    { value: 0, label: 'Общая, ОСН' },
                    { value: 1, label: 'Упрощенная доход, УСН доход' },
                    { value: 2, label: 'Упрощенная доход минус расход, УСН доход - расход' },
                    { value: 3, label: 'Единый налог на вмененный доход, ЕНВД' },
                    { value: 4, label: 'Единый сельскохозяйственный налог, ЕСН' },
                    { value: 5, label: 'Патентная система налогообложения, Патент' },
                ]
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('entities', {
                entity: state => state.entity
            }),
            ...mapState('entitySetting', {
                oldSetting: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const { site_id, id } = this.$route.params;
            if(site_id) {
                await store.dispatch('sites/get', { id: site_id });
            }
            if(site_id && id) {
                await store.dispatch('entities/get', { site_id, id });
                await store.dispatch('entitySetting/get', { entity_id: id });
                this.setting = this.oldSetting;
                if(!this.setting.files) {
                    this.setting.files = {
                        legal_information: null
                    };
                }
                if(!this.setting.yandex_delivery) {
                    this.setting.yandex_delivery = {
                        optional_return: false
                    }
                }
                if(!this.setting.yandex_delivery.coordinates) {
                    this.setting.yandex_delivery.coordinates = [];
                }
            }
        },

        methods: {
            async setFile(prop, file) {
                this.setting.files[prop] = await store.dispatch('files/upload', { file });
            },
            removeFile(prop) {
                this.setting.files[prop] = null;
            },
            async save() {
                const { id } = this.$route.params;
                try {
                    store.commit('entitySetting/SET_ENTITY', this.setting);
                    await store.dispatch('entitySetting/save', { entity_id: id });
                    this.goBack();
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            goToList() {
                const { site_id } = this.$route.params;
                if(site_id) {
                    this.$router.push(`/sites/${site_id}/entities`);
                } else {
                    this.$router.push('/sites');
                }
            },
            goBack() {
                const { site_id, id } = this.$route.params;
                if(site_id && id) {
                    this.$router.push(`/sites/${site_id}/entities/${id}/detail`);
                } else if(site_id) {
                    this.$router.push(`/sites/${site_id}/entities`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>
